import { CoreMessageFieldsFragment } from '@pm/graphql';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { trackAnalyticsEvent } from '@pm/analytics';
import MessageName from '../MessageName';
import { useMessageName } from '../../hooks/useMessageName';
import { Attachments } from '../Attachments';
import { DateTime } from '../DateTime';
import { useMarkdownComponents } from '../../hooks/use-markdown-components/use-markdown-components';

type MessageProps = {
  message: CoreMessageFieldsFragment;
  userId: string;
  brand: string;
};

const Message = ({ message, userId, brand }: MessageProps) => {
  const senderName = useMessageName(brand, message.sender);
  const { p, ul, a } = useMarkdownComponents({ variant: 'subdued' });

  return (
    <div className="mt-3 w-full shadow">
      <div className="bg-grey-50 flex items-center">
        <MessageName variant="sender">{senderName}</MessageName>
        <div className="text-grey-400 flex-1 p-4 text-right text-sm leading-8">
          <span className="ml-2">
            <DateTime value={message.createdAt} format="Pp" />
          </span>
        </div>
      </div>
      <div className="text-md bg-white px-4 py-6">
        <ReactMarkdown
          components={{
            p,
            a,
            ul,
          }}
          rehypePlugins={[rehypeRaw]}
        >
          {message.content}
        </ReactMarkdown>
      </div>
      {'attachments' in message && (
        <Attachments
          attachments={message.attachments}
          handleAttachmentClick={() =>
            trackAnalyticsEvent('File downloaded', {
              userId,
              date: new Date().toLocaleString(),
              source: {
                category: 'Secure Message',
                messageId: message.id,
              },
            })
          }
        />
      )}
    </div>
  );
};

export { Message };
