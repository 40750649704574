import { To } from 'react-router-dom';
import { Card, Button, ButtonProps, ButtonLink, Text } from '@pm/ui';

type SharedActionProps = {
  text: string;
  intent?: Extract<ButtonProps['intent'], 'primary' | 'secondary' | 'ghost'>;
};

type ActionButton = SharedActionProps & {
  to?: never;
  disabled?: boolean;
  onClick?: () => void;
};

type ActionLink = SharedActionProps & {
  to: To;
  disabled?: never;
  onClick?: never;
};

type Action = ActionButton | ActionLink;

type WorkflowStepCardProps = {
  title: string;
  description?: string;
  action: Action;
  secondaryAction?: Action;
};

export const WorkflowStepCard = ({
  title,
  description,
  action,
  secondaryAction,
}: WorkflowStepCardProps) => {
  const { text: actionText, intent: primaryIntent, ...actionProps } = action;
  let secondaryActionEl;

  const primaryActionEl = actionProps.to ? (
    <ButtonLink {...actionProps} width="full" intent={primaryIntent}>
      {actionText}
    </ButtonLink>
  ) : (
    <Button {...actionProps} width="full" intent={primaryIntent}>
      {actionText}
    </Button>
  );

  if (secondaryAction) {
    const {
      text: secondaryActionText,
      intent: secondaryIntent,
      ...secondaryActionProps
    } = secondaryAction;

    secondaryActionEl =
      secondaryAction &&
      (secondaryActionProps.to ? (
        <ButtonLink
          {...secondaryActionProps}
          width="full"
          intent={secondaryIntent}
        >
          {secondaryActionText}
        </ButtonLink>
      ) : (
        <Button {...secondaryActionProps} width="full" intent={secondaryIntent}>
          {secondaryActionText}
        </Button>
      ));
  }

  return (
    <Card size="large">
      <div className="flex-col p-s space-y-m">
        <div className="space-y-s">
          <Text as="h1" size="headingXS">
            {title}
          </Text>
          <Text as="p" className="text-gray-500 whitespace-pre-line">
            {description}
          </Text>
        </div>

        <div className="flex gap-l">
          {primaryActionEl}
          {secondaryActionEl}
        </div>
      </div>
    </Card>
  );
};
